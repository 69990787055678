import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import 'suneditor/dist/css/suneditor.min.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import profile from '../componentes/Imagens/profile.jpg'
import { useUtilitarios } from '../hooks/useUtilitarios'
import { atualizaPagamentoMP, consultaPagamentosMP } from '../service/MercadoPago'


export default function DetalheFiliacao() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idFederacao = queryParams.get('f');
    const [filiacaoExt, setFiliacaoExt] = useState(queryParams.get('fl'));


    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        equipes: firebase.firestore().collection("equipes"),
        filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
        federacoes: firebase.firestore().collection('federacoes')

    })
    const [equipe, setEquipe] = useState("")
    const [usuario, setUsuario] = useState({})
    console.log('usuario');

    const [usuarioFiliacao, setUsuarioFiliacao] = useState({})
    const [usuarioSelecionado, setUsuarioSelecionado] = useState(null)

    const [filiacao, setFiliacao] = useState({})
    const [federacao, setFederacao] = useState({})

    const [pagamentoPendente, setPagamentoPendente] = useState({})
    const [ultimoPagamento, setUltimoPagamento] = useState({})
    const [pagamentosAprovados, setPagamentosAprovados] = useState([])

    const [carteirinhas, setCarteirinhas] = useState([])
    const [ultimaCarteirinhaDigital, setTltimaCarteirinhaDigital] = useState({})


    const [idade, setIdade] = useState({})
    const [competidores, setCompetidores] = useState([])
    console.log('competidores: ', competidores);

    const [filiacoes, setFiliacoes] = useState([])

    useEffect(() => {
        let _filiacoes = [];

        if (usuario.id) {
            if (usuario?.filiacoes?.length > 0) {
                const usuarioFiliado = usuario?.filiacoes.some(fd => fd === idFederacao)
                if (usuarioFiliado) {
                    _filiacoes.push(usuario);
                }
            }
        }

        for (let competidor of competidores) {
            if (competidor?.filiacoes?.length > 0) {
                const usuarioFiliado = competidor?.filiacoes.some(fd => fd === idFederacao)
                if (usuarioFiliado) {
                    _filiacoes.push(competidor);
                }
            }
        }

        console.log('_filiacoes: ', _filiacoes);

        setFiliacoes(_filiacoes);

    }, [usuario, competidores])

    console.log('filiacoes: ', filiacoes);



    const utilitarios = useUtilitarios()

    useEffect(() => {

        servicos.auth.onAuthStateChanged(async (user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))

            if (user || idUser) {

                servicos.usuarios.doc(user?.uid ? user.uid : idUser).get().then(async (doc) => {
                    if (doc.exists) {
                        const _usuario = doc.data()
                        setUsuario(_usuario);
                        carregarFederacao();
                        carregarUsuarioFiliacao(_usuario);

                    }

                })

            } else {
                window.location = '/'
            }

        })


    }, [])

    function carregarFederacao() {
        servicos.federacoes.doc(idFederacao).get().then(snap => {
            if (snap.exists) {
                const _federacao = snap.data()
                setFederacao(_federacao);
            }
        })
    }

    async function carregarUsuarioFiliacao(_usuario) {

        const competidorOuResponsavel = 2;
        const aluno = 3;

        if (_usuario.usuario === competidorOuResponsavel) {

            setUsuarioFiliacao(_usuario);

            await utilitarios.atualizarStatusFiliacao(idFederacao, [_usuario]);

            // Carregar equipe e professor
            if (_usuario.equipe) {

                servicos.equipes.doc(_usuario.equipe).get().then((e) => {
                    setEquipe(e.data().equipe ? e.data().equipe : '')
                })

            }


            // carregar filiação

            if (_usuario?.filiacoes?.length > 0) {
                if (_usuario?.filiacoes.includes(idFederacao)) {

                    const docRef = await servicos.filiacoes.where('idFiliado', '==', _usuario.id).where('tipoUsuario', '==', _usuario.tipoUsuario).get();
                    const _filiacao = docRef.docs.map(d => d.data())[0];
                    setFiliacao(_filiacao);

                    // Carregar Pagamento
                    if (_filiacao.id) {
                        servicos.filiacoes.doc(_filiacao.id).collection('pagamentos').get().then(snap => {


                            const pagamentos = snap.docs.map(d => d.data())

                            if (pagamentos.length > 0) {
                                const _ultimoPagamento = pagamentos.filter(pgto => pgto.status == 2).sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
                                setUltimoPagamento(_ultimoPagamento)

                                const existePagamentoPendente = pagamentos.some(pgto => pgto.status !== 2);

                                if (existePagamentoPendente) {
                                    const _pagamentoPendente = pagamentos.find(pgto => pgto.status !== 2)
                                    setPagamentoPendente(_pagamentoPendente);
                                }
                            }

                        })

                    }

                }
            }

        }

        if (_usuario.usuario === aluno) {

            servicos.usuarios.doc(_usuario.id).collection('competidores').get().then(async snap => {
                if (snap.size > 0) {
                    const data = snap.docs.map(d => d.data())

                    const _competidores = data.map(comp => {
                        return { ...comp, responsavel: _usuario.id }
                    })
                    setCompetidores(_competidores)

                    const usuarios = [_usuario, ..._competidores]

                    await utilitarios.atualizarStatusFiliacao(idFederacao, usuarios)


                }
            })


        }

    }

    useEffect(async () => {
        if (filiacaoExt) {
            const docRefFiliacao = await servicos.filiacoes.doc(filiacaoExt).get();
            const dataFiliacao = docRefFiliacao.data();
            setFiliacao(dataFiliacao);

            if (dataFiliacao.idResponsavel) {
                // Carregar Dados do Usuário
                const docRefAluno = await servicos.usuarios.doc(dataFiliacao.idResponsavel).collection('competidores').doc(dataFiliacao.idFiliado).get()


                if (docRefAluno.exists) {
                    const dataAluno = docRefAluno.data()
                    setUsuarioFiliacao(dataAluno)
                    // const index = competidores.findIndex(comp => comp.id === dataAluno.id)
                    // setUsuarioSelecionado(index)

                    // Carregar equipe 
                    if (dataAluno.equipe) {

                        servicos.equipes.doc(dataAluno.equipe).get().then((e) => {
                            setEquipe(e.data().equipe ? e.data().equipe : '')
                        })

                    }
                }


            } else {
                setUsuarioFiliacao(usuario)
                // setUsuarioSelecionado('eu')
                if (usuario.equipe) {

                    servicos.equipes.doc(usuario.equipe).get().then((e) => {
                        setEquipe(e.data().equipe ? e.data().equipe : '')
                    })

                }
                // Carregar equipe 
                if (usuario.equipe) {

                    servicos.equipes.doc(usuario.equipe).get().then((e) => {
                        setEquipe(e.data().equipe ? e.data().equipe : '')
                    })

                }
            }

            carregarUltimoPagamento(filiacaoExt)

        }
    }, [filiacaoExt, usuario])


    function carregarUltimoPagamento(idFiliacao) {
        // Carregar Último Pagamento
        servicos.filiacoes.doc(idFiliacao).collection('pagamentos').get().then(snap => {
            const pagamentos = snap.docs.map(d => d.data())

            if (pagamentos.length > 0) {
                const _ultimoPagamento = pagamentos.filter(pgto => pgto.status == 2).sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
                setUltimoPagamento(_ultimoPagamento)

                const existePagamentoPendente = pagamentos.some(pgto => pgto.status !== 2);

                if (existePagamentoPendente) {
                    const _pagamentoPendente = pagamentos.find(pgto => pgto.status !== 2)
                    setPagamentoPendente(_pagamentoPendente);
                }
            }

        })
    }

    useEffect(async () => {
        if (pagamentoPendente.id) {
            if (pagamentoPendente?.status !== 2) {

                for (let i = 1; i <= 4; i++) {

                    const external_reference = `flc-${idFederacao}-${filiacao.id}-${pagamentoPendente.id}`;
                    const chavePagamento =
                        pagamentoPendente.chavePagamento ?
                            pagamentoPendente.chavePagamento :
                            federacao.chavePagamento ?
                                federacao.chavePagamento : null;

                    const response = await atualizaPagamentoMP(external_reference, chavePagamento)

                    if (!response) {
                        continue;
                    }

                    if (response?.semPgto) {
                        break
                    }

                    if (response.pgtoJaAprovado || response.pgtoAlterado) {
                        window.location.reload();
                    }

                    await new Promise((resolve) => {
                        setTimeout(resolve, 4000)
                    })


                }


            }
        }
    }, [pagamentoPendente]);

    function mensagemStatus(status) {
        let mensagem = ''

        if (pagamentoPendente?.tipoPagamento === 'online') {
            status = 5;
        }

        switch (status) {
            case 0: mensagem = 'Pendente de Pagamento';
                break;
            case 1: mensagem = 'Aguardando aprovação da documentação';
                break;
            case 2: mensagem = 'Aprovada';
                break;
            case 3: mensagem = 'Documentação Reprovada';
                break;
            case 4: mensagem = 'Vencida (pendente de renovação)';
                break;
            case 5: mensagem = 'Aguardando aprovação do pagamento';
                break;
            default: mensagem = '';
                break
        }

        return mensagem;

    }
    function mensagemCor(status) {
        let cor = ''

        switch (status) {
            case 1: case 'Pendente': cor = 'orange'; break;
            case 2: case 'Aprovada': cor = 'green'; break;
            case 3: case 'Reprovada': cor = 'red'; break;
            case 4: case 'Vencida': cor = 'purple'; break;
            default: cor = ''; break;
        }

        return cor;

    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    async function mensagemConfirmacao(titulo, texto) {

        const result = await Swal.fire({
            title: titulo,
            html: texto,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            customClass: {
                content: 'swal-message'
            }
        });
        if (result.isConfirmed) {
            return true;
        } else if (result.isDismissed || result.isDenied) {
            return false;
        }
    }

    function converterData(data) {

        const _data = moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('YYYY-MM-DD')

        const [ano, mes, dia] = _data.split('-')

        return moment([parseInt(ano) + 1, mes, dia].join('-')).format('DD/MM/YYYY')

    }


    function dataVencimento(data) {

        const _data = moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('YYYY-MM-DD')

        const [ano, mes, dia] = _data.split('-')
        const vencimento = federacao?.regrasFiliacao?.vencimentoAnuidade;
        const umAnoAposAprovacao = '1';
        const aoFimDoAno = '2';


        if (vencimento == umAnoAposAprovacao) {
            return moment([parseInt(ano) + 1, mes, dia].join('-')).format('DD/MM/YYYY');
        } else if (vencimento == aoFimDoAno) {
            return `31/12/${ano}`;
        }


    }




    const handleCompetidor = async (event) => {
        event.persist();
        setUsuarioSelecionado(event.target.value)
        setUsuarioFiliacao({})
        setFiliacao({})
        setEquipe('')
        setUltimoPagamento({})
        setPagamentoPendente({})
        // setCompetidorSelecionado(event.target.value);
        if (event.target.value !== "") {
            if (event.target.value === "eu") {

                setUsuarioFiliacao(usuario);

                // Carrega Equipe
                if (usuario?.equipe) {
                    servicos.equipes.doc(usuario.equipe).get().then((e) => {
                        setEquipe(e.data()?.equipe ? e.data().equipe : '');
                    })

                }

                // Carregar os dados da filiação do usuário selecionado

                if (usuario?.filiacoes?.length > 0 && usuario?.filiacoes.includes(idFederacao)) {

                    const snap = await servicos.filiacoes.where('idFiliado', '==', usuario.id).where('tipoUsuario', '==', usuario.tipoUsuario).get();

                    if (!snap.empty) {
                        const data = snap.docs.map(d => d.data())[0]
                        setFiliacao(data);

                        // Carregar Último Pagamento
                        servicos.filiacoes.doc(data.id).collection('pagamentos').get().then(snap => {
                            const pagamentos = snap.docs.map(d => d.data())

                            if (pagamentos.length > 0) {
                                const _ultimoPagamento = pagamentos.filter(pgto => pgto.status == 2).sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
                                setUltimoPagamento(_ultimoPagamento)

                                const existePagamentoPendente = pagamentos.some(pgto => pgto.status !== 2);

                                if (existePagamentoPendente) {
                                    const _pagamentoPendente = pagamentos.find(pgto => pgto.status !== 2)
                                    setPagamentoPendente(_pagamentoPendente);
                                }
                            }

                        })
                    } else {
                        setFiliacao({ semFiliacao: true })
                    }


                } else {
                    setFiliacao({ semFiliacao: true })
                }

            } else {

                const competidor = competidores[event.target.value]

                setUsuarioFiliacao(competidor)

                // Carregar Equipe
                if (competidor.equipe) {
                    servicos.equipes.doc(competidor.equipe).get().then((e) => {
                        setEquipe(e.data()?.equipe ? e.data()?.equipe : '')
                    })

                }

                if (competidor?.filiacoes?.length > 0) {
                    if (competidor?.filiacoes.includes(idFederacao)) {

                        const snap = await servicos.filiacoes.where('idFiliado', '==', competidor.id).where('tipoUsuario', '==', competidor.tipoUsuario).get();

                        if (!snap.empty) {
                            const data = snap.docs.map(d => d.data())[0]
                            setFiliacao(data);

                            // Carregar Último Pagamento
                            servicos.filiacoes.doc(data.id).collection('pagamentos').get().then(snap => {
                                const pagamentos = snap.docs.map(d => d.data())

                                if (pagamentos.length > 0) {
                                    const _ultimoPagamento = pagamentos.filter(pgto => pgto.status == 2).sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
                                    setUltimoPagamento(_ultimoPagamento)

                                    const existePagamentoPendente = pagamentos.some(pgto => pgto.status !== 2);

                                    if (existePagamentoPendente) {
                                        const _pagamentoPendente = pagamentos.find(pgto => pgto.status !== 2)
                                        setPagamentoPendente(_pagamentoPendente);
                                    }
                                }

                            })
                        } else {
                            setFiliacao({ semFiliacao: true })
                        }

                    } else {
                        setFiliacao({ semFiliacao: true })
                    }
                } else {
                    setFiliacao({ semFiliacao: true })
                }

            }
        }
    }

    const handleSelecionarFiliacao = async (competidor) => {
        const snap = await servicos.filiacoes.where('idFiliado', '==', competidor.id).where('tipoUsuario', '==', competidor.tipoUsuario).get()

        if (!snap.empty) {
            const idFiliacao = snap.docs.map(d => d.id)[0];
            setFiliacaoExt(idFiliacao);
            setPagamentoPendente({});

            // window.location.href = `/detalheFiliacao?f=${idFederacao}&fl=${idFiliacao}`;
        }
    }

    async function excluirFiliacao() {

        const titulo = 'Confirma o cancelamento da Filiação?';
        const msg = filiacao.statusFiliacao === 2 ? 'Atenção: A sua inscrição está aprovada e <span  style="color:green; font-weight:bold;">Ativa</span>. Se cancelar não poderá mais usufruir dos benefíficios e vantagems da Filiação. Confirma o cancelamento mesmo assim?' :
            filiacao.statusFiliacao === 1 ?
                'Atenção: A sua inscrição está com situação <span  style="color:orange; font-weight:bold;">Pendente</span>, o que significa que a documentação anexada ainda não foi aprovada. Deseja realizar o cancelamento mesmo assim?' :
                'A filiação será desvinculada do seu cadastro. Caso queira filiar-se novamente, basta preencher com os documentos necessários para a filiação.';


        if (! await mensagemConfirmacao(titulo, msg)) {
            return;
        }

        const dadosExclusao = {
            idFederacao: idFederacao,
            filiacao: filiacao,
            usuarioFiliacao: usuarioFiliacao,
            usuario: usuario,
            pagamentoPendente: pagamentoPendente,
            pagamentosAprovados: pagamentosAprovados
        }
        mensagemLoading('cancelando filiação...')

        try {
            await utilitarios.excluirFiliacao(dadosExclusao);
            Swal.fire('Sucesso', 'Filiação cancelada. Você pode filiar-se novamente quando quiser.', 'success').then(() => {
                window.location = '/federacoes'
            })
        } catch (error) {
            mensagemErro('Não foi possível concluír a exclusão. Entre em contato com o suporte.')
            console.log('erro: ', error.message);
        }


    }


    function exibirBotaoCarteirinhaDigital() {

        const sim = '1';
        const opcional = '2'

        return (

            federacao?.carteirinha?.carteirinhaDigital == sim ||
            (federacao?.carteirinha?.carteirinhaDigital == opcional)

        )
    }

    function urlBotaoCarteirinhaDigital() {

        const sim = '1';
        const opcional = '2';
        let url = '#';

        if ([sim, opcional].includes(federacao?.carteirinha?.carteirinhaDigital)) {

            if (
                federacao?.carteirinha?.carteirinhaDigital === opcional &&
                filiacao?.adquirirCarteirinhaDigital !== '1' &&
                filiacao?.habilitarCarteirinhaDigital !== '1'
            ) {
                url = `/carteirinha?fl=${filiacao.id}&fd=${idFederacao}&tp=dig`;
            }

            if (filiacao?.habilitarCarteirinhaDigital === '1') {
                url = `/carteirinhaDigital?fl=${filiacao.id}&fd=${idFederacao}`;
            }


        }

        return url;

    }

    function linkFiliarSe() {

        let url = '';

        if (usuarioSelecionado === 'eu') {
            url = `/filiacao/${idFederacao}?comp=eu`
        } else {
            url = `/filiacao/${idFederacao}?comp=${usuarioSelecionado}`
        }

        return url;
    }


    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>{'Dados da Filiação'}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Confira a baixo os dados de sua Filiação</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">



                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}>

                                            {usuario.usuario === 3 && (
                                                <div class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name"
                                                    >
                                                        <h4>Selecione o Filiado <i class="fa fa-hand-o-down" aria-hidden="true"></i></h4>
                                                        <select name="select" id="select"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%', cursor:'pointer' }} onChange={handleCompetidor} value={usuarioSelecionado}>
                                                            <option value="">
                                                               {'>>> Selecione o filiado <<<'} 
                                                                </option>
                                                            <option value="eu">{usuario.nome} - (Responsável) </option>
                                                            {competidores.sort((a, b) => a.nome - b.nome).map((c, index) =>
                                                                <option value={index} >{c.nome} {c.sobrenome} - {c.cpf}</option>
                                                            )}
                                                        </select>
                                                    </span>
                                                </div>
                                            )}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(usuario.usuario === 3 && filiacoes.length > 0) && (
                            <div class="vc_row wpb_row vc_row-fluid">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="vc_empty_space" style={{ height: 28 }}>


                                                <div class="contact-form-name vc_col-sm-6">
                                                    {/* <h3>Suas filiações</h3> */}
                                                    <span class="wpcf7-form-control-wrap your-name"
                                                    >
                                                        <table width={'100%'} >
                                                            <tr bgcolor="#000">
                                                                <th style={{ color: 'white' }}><b>Suas Filiações</b></th>
                                                                <th style={{ color: 'white' }}></th>

                                                            </tr>
                                                            <tbody>

                                                                {filiacoes.map(flc => (
                                                                    <tr key={flc.id} >
                                                                        <td>{`${flc.nome} ${flc.sobrenome}`}</td>
                                                                        <td>

                                                                            <a onClick={() => {
                                                                                handleSelecionarFiliacao(flc)
                                                                            }} style={{ width: "100%", }} title="Clique aqui e veja o detalhe da inscrição">
                                                                                <span className='badge-danger badge-doc-reprovada' style={{ padding: '6px 9px', cursor: 'pointer', background: 'rgb(23, 162, 184)' }}>
                                                                                    <i className="fa  fa-search" aria-hidden="true" ></i>
                                                                                </span>

                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </span>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">



                            {filiacao.semFiliacao && (

                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <h3 style={{ marginLeft: '43px' }}>Usuário Sem Filiação</h3>
                                    <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                        <a href={linkFiliarSe()} class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-file" aria-hidden="true" style={{ color: "#fff" }}></i> Filiar-se</a>
                                    </div>
                                </div>
                            )}

                            {usuarioFiliacao.id && filiacao.id && (
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                                <div class="package-style-1 pnchr-package clearfix vc_col-sm-3">
                                                    <div style={{ height: '300px', background: `url('${filiacao?.urlImagemFoto ? filiacao.urlImagemFoto : profile}') no-repeat center/contain`, padding: '0px' }}>
                                                        {/* <img src={filiacao.urlImagemFoto ? filiacao.urlImagemFoto : profile} alt="" title="image-here-1" style={{objectFit:'cover', objectPosition:'center', height:'100%', width:'100%'}} /> */}
                                                    </div>
                                                </div>
                                                <div role="form" class="wpcf7 row vc_col-sm-8" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr" >
                                                    <div class="screen-reader-response"></div>
                                                    <p class="contact-form-name vc_col-sm-12">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h2>Filiação Nº {filiacao.numeroFiliacao ? filiacao.numeroFiliacao.toString().padStart(4, '0') : ''}</h2><br />
                                                            <div className="ratings-widget__details-title" ><h4 style={{ color: "#585858" }}>Filiado: {filiacao.nomeFiliado
                                                            }</h4></div>
                                                            <div className="ratings-widget__details-title" ><h4 style={{ color: "#585858" }}>CPF: {filiacao.cpfFiliado}</h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Data de Nascimento: {usuarioFiliacao.dataNascimento ?
                                                                moment(usuarioFiliacao.dataNascimento).format('DD/MM/YYYY') : 'não informada'
                                                            }</h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Sexo: {usuarioFiliacao.sexo}</h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Equipe: {equipe}</h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Faixa: {filiacao.graduacao}</h4></div><br />
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Vencimento: <span>{filiacao?.dtAprovado ?
                                                                dataVencimento(filiacao?.dtAprovado) : '--'}</span></h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Situação: <span style={{ color: mensagemCor(filiacao?.statusFiliacao) }}>{mensagemStatus(filiacao.statusFiliacao)}</span></h4></div>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {usuarioFiliacao.id && filiacao.id && (
                                <>
                                    <div class="wpb_column vc_column_container vc_col-sm-8" style={{ marginTop: 20 }}>
                                        <div class="package-style-1__btn vc_col-sm-5" style={{ marginBottom: '10px' }}>
                                            <a href={`/filiacao/${idFederacao}?fl=${filiacao.id}`} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: '#17a2b8' }}><i class="fa  fa-file" aria-hidden="true" style={{ color: "#fff" }}></i>  {filiacao.statusFiliacao === 4 ? 'Renovar Filiação' : 'Detalhes da Filiação'}</a>
                                        </div>
                                        {filiacao?.statusFiliacao === 2 && (
                                            <>
                                                {exibirBotaoCarteirinhaDigital() && (
                                                    <div class="package-style-1__btn vc_col-sm-5" style={{ marginBottom: '10px' }}>
                                                        <a href={urlBotaoCarteirinhaDigital()} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: '#ffc107' }}><i class="fa fa-newspaper-o" aria-hidden="true" style={{ color: "#fff" }}></i> Carteirinha Digital</a>
                                                    </div>
                                                )}
                                                {federacao?.carteirinha?.carteirinhaFisica != '0' && (
                                                    <div class="package-style-1__btn vc_col-sm-5" style={{ marginBottom: '10px' }}>
                                                        <a href={`/carteirinha?fl=${filiacao.id}&fd=${idFederacao}`} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: 'rgb(238, 92, 7)' }}><i class="fa fa-newspaper-o" aria-hidden="true" style={{ color: "#fff" }}></i> Carteirinha Física</a>
                                                    </div>
                                                )}


                                            </>

                                        )}


                                        <div class="package-style-1__btn vc_col-lg-5" style={{ marginBottom: '10px' }}>
                                            <div class="btn_defoult btn_fill vc_col-lg-12" style={{ cursor: 'pointer', background: '#c21111' }} onClick={() => excluirFiliacao()} ><i class="fa fa-times" aria-hidden="true" style={{ color: "#fff" }}></i> Cancelar Filiação</div>
                                        </div>
                                    </div>

                                </>
                            )}

                            <div class={`wpb_column vc_column_container ${filiacao.id ? 'vc_col-sm-8' : 'vc_col-sm-12'}`}>
                                <div class={`package-style-1__btn ${filiacao.id ? 'vc_col-sm-5' : 'vc_col-sm-3'}`} style={{ marginBottom: '10px' }}>
                                    <a href={`/federacao/${idFederacao}`} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: '#17a2b8' }}><i class="fa fa-arrow-left" aria-hidden="true" style={{ color: "#fff" }}></i> Voltar</a>
                                </div>

                                {pagamentoPendente?.id && (
                                    <div class={`package-style-1__btn ${filiacao.id ? 'vc_col-sm-5' : 'vc_col-sm-3'}`} style={{ marginBottom: '10px' }}>
                                        <a href={`/filiacao/pagamento?fd=${idFederacao}&fl=${filiacao.id}`} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: 'orange' }}>Realizar Pagamento <i class="fa fa-arrow-right" aria-hidden="true" style={{ color: "#fff" }}></i></a>
                                    </div>
                                )}
                            </div>





                        </div>



                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
