import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Swal from 'sweetalert2'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import './Filiacao.css'



export default function MembrosFiliados() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idFederacao = queryParams.get('f');

  const [servicos] = useState({
    equipes: firebase.firestore().collection("equipes"),
    usuarios: firebase.firestore().collection("usuarios"),
    federacoes: firebase.firestore().collection('federacoes'),
    filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes')
  })

  const [filiacoes, setFiliacoes] = useState([]);
  console.log('filiacoes: ', filiacoes);

  const [ordenarPor, setOrdenarPor] = useState('numeracao');

  useEffect(async () => {

    mensagemLoading('Carregando Filiados...')

    await new Promise((resolve) => {
      setTimeout(resolve, 1)
    })

    // Carregar Filiações
    servicos.federacoes.doc(idFederacao).collection('filiacoes').where('statusFiliacao', '==', 2).get().then(async snap => {

      if (snap.empty) {
        Swal.close()
      }

      let _filiacoes = snap.docs.map(d => d.data());
      const acoes = [];


      _filiacoes.forEach(async (filiacao, index) => {


        if (filiacao.idResponsavel) {

          const atualizarEquipe = servicos.usuarios.doc(filiacao.idResponsavel).collection('competidores').doc(filiacao.idFiliado).get()
            .then(async snapAluno => {

              if (snapAluno.exists) {

                const dadosAluno = snapAluno.data()

                if (dadosAluno.equipe) {

                  await servicos.equipes.doc(dadosAluno.equipe).get()
                    .then(snapEquipe => {

                      if (snapEquipe.exists) {
                        const dadosEquipe = snapEquipe.data()
                        _filiacoes[index].equipe = dadosEquipe.equipe
                      }

                      if (index === _filiacoes.length - 1) {
                        Swal.close()

                      }

                    })
                }
              } else {
                if (index === _filiacoes.length - 1) {
                  Swal.close()

                }

              }

            })

          acoes.push(atualizarEquipe)

        } else {

          const atualizarEquipe = servicos.usuarios.doc(filiacao.idFiliado).get().then(async snapUsuarios => {
            if (snapUsuarios.exists) {

              const dadosUsuario = snapUsuarios.data()

              if (dadosUsuario.equipe) {

                await servicos.equipes.doc(dadosUsuario.equipe).get().then(snapEquipe => {
                  if (snapEquipe.exists) {
                    const dadosEquipe = snapEquipe.data()
                    _filiacoes[index].equipe = dadosEquipe.equipe
                  }

                  if (index === _filiacoes.length - 1) {
                    Swal.close()

                  }

                })

              }
            } else {
              if (index === _filiacoes.length - 1) {
                Swal.close()

              }

            }

          })


          acoes.push(atualizarEquipe)

        }

      })

      await Promise.all(acoes);


      Swal.close()
      setFiliacoes(_filiacoes);
    })


  }, [])

  function mensagemLoading(msg) {
    Swal.fire({
      title: 'Aguarde',
      html: msg,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })
  }

  function ordenacao() {

    const ordemFaixa = (faixa) =>{

      let valor = 0;

      switch (faixa) {
        case 'BRANCA': valor = 0; break;
        case 'CINZA': valor = 1; break;
        case 'AMARELA': valor = 2; break;
        case 'LARANJA': valor = 3; break;
        case 'VERDE': valor = 4; break;
        case 'AZUL': valor = 5; break;
        case 'ROXA': valor = 6; break;
        case 'MARROM': valor = 7; break;
        case 'PRETA': valor = 8; break;
        case 'Não Informada': 
        case '': 
        case undefined: 
        valor = 9; break;
      }

      return valor;

    }

    if (ordenarPor === 'alfabetica') {
      return (a, b) => { 
        if(a.nomeFiliado > b.nomeFiliado) return 1;
        if(a.nomeFiliado < b.nomeFiliado) return -1;
        return 0; 
      }
    }
    else if (ordenarPor === 'equipe') {
      return (a, b) => { 
        if(a.equipe > b.equipe) return 1;
        if(a.equipe < b.equipe) return -1;
        return 0; 
      }
    } else if (ordenarPor === 'faixa') {
      return (a, b) => { 
        if(ordemFaixa(a.graduacao)  > ordemFaixa(b.graduacao)) return 1;
        if(ordemFaixa(a.graduacao) < ordemFaixa(b.graduacao)) return -1;
        return 0; 
      }
    } else {
      return (a, b) => { 
        if(a.numeroFiliacao > b.numeroFiliacao) return 1;
        if(a.numeroFiliacao < b.numeroFiliacao) return -1;
        return 0; 
      }
    }
  }


  return (
    <div className="body-wrapper blank-loder">
      <Header />
      <div class="section_fullwidth">
        <div class="headline headline_img">
          <div class="headline__inner">
            <div class="headline__title">
              <h1>Membros Filiados</h1>
            </div>
            <div class="headline__description">
              <span>Confira abaixo os membros filiados a esta Federação/Agremiação</span>
            </div>
          </div>

        </div>
      </div>
      <div class="section_default section-single-class-layout clearfix">
        <main class="section_default">
          <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="wpb_text_column wpb_content_element ">
                    <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {/* <div class={`invisible`}></div> */}

                      <p class="contact-form-name vc_col-sm-2">
                        <span class="wpcf7-form-control-wrap your-name"
                        >
                          <h6>Ordenar por:</h6>
                          <select name="select" id="select"
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%' }} onChange={(e) => setOrdenarPor(e.target.value)}>
                            <option value="numeracao">Numeracao </option>
                            <option value="alfabetica">Alfabeticamente </option>
                            <option value="equipe">Equipes </option>
                            <option value="faixa"> Faixas </option>

                          </select>
                        </span>
                      </p>


                      <div onClick={() => { window.location = `/federacao/${idFederacao}` }} style={{ background: '#007bff', cursor: 'pointer', margin: '15px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-1`}>
                        <button style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" class="wpcf7-form-control wpcf7-pnchr_submit" ><i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar</button>
                      </div>
                    </div>

                    <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>

                      <div className="card-body--">
                        {true && (
                          <div className="table-stats order-table ov-h">
                            <Table>
                              <Thead>
                                <Tr>
                                  <Th className='schedule-table__day'>Filiado</Th>
                                  <Th className='schedule-table__day'>Nº</Th>
                                  <Th className='schedule-table__day'>Equipe</Th>
                                  <Th className='schedule-table__day'>Graduação</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {filiacoes
                                  .sort(ordenacao())
                                  .map((filiacao) =>
                                    <Tr key={filiacao.id} >
                                      <Td>{filiacao.nomeFiliado}</Td>
                                      <Td>{filiacao.numeroFiliacao.toString().padStart(4, '0')}</Td>
                                      <Td>{filiacao.equipe ? filiacao.equipe : 'Não Informada'}</Td>
                                      <Td>{filiacao.graduacao ? filiacao.graduacao : 'Não Informada'}</Td>
                                    </Tr>
                                  )}

                              </Tbody>
                            </Table>
                            <p style={{ textAlign: 'center' }} >Total: {filiacoes.length} </p>
                          </div>
                        )}

                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
      <Footer />
    </div>
  );
}
