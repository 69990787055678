import axios from 'axios'
const baseUrl = "https://api.mercadopago.com"
// const baseUrlProcompetidor = "http://localhost:5000/procompetidor-7867b/us-central1/app"
const baseUrlProcompetidor = "https://us-central1-procompetidor-7867b.cloudfunctions.net/app"

// const token = "TEST-1008893631732682-011620-0129e8f40ad145284919b3393e8a04cb-554737429"
// const publicKey = "TEST-aeb30924-3ee6-4601-8de0-8933dbf655e5"
const token = "APP_USR-1008893631732682-011620-32290672d87fd7e05454a419f583eafa-554737429"
// const token = "TEST-1008893631732682-011620-0129e8f40ad145284919b3393e8a04cb-554737429"
// const publicKey = "TEST-aeb30924-3ee6-4601-8de0-8933dbf655e5"
const tokenProcompetidor = "APP_USR-8034385883906385-072611-f665f4a514e0538cacbde0cb2657c35e-1153629660"



export const geraPreferencia = (dados, chave,device_id) => {
    if (chave === 0) {
        return (async () => {
            try {
                const URL = baseUrl + "/checkout/preferences";
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token)
                headers.append('X-meli-session-id', device_id)
                headers.append('x-integrator-id', "dev_113a02781c5a11eeb2d71a7506ef66b5")
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "post",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                console.log(data)
                return data;
            } catch {
                return 2
            }
        })();
    } else {
        return (async () => {
            try {
                const URL = baseUrl + "/checkout/preferences";
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + tokenProcompetidor)
                headers.append('X-meli-session-id', device_id)
                headers.append('x-integrator-id', "dev_113a02781c5a11eeb2d71a7506ef66b5")
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "post",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                console.log(data)
                return data;
            } catch {
                return 2
            }
        })();
    }

}

export const getPagamento = (id, chave) => {
    return (async () => {
        try {
            const URL = baseUrlProcompetidor + "/getPedido?id=" + id+"&chave="+chave;
            var headers = new Headers();
            headers.append("Content-Type", "application/json")
            const response = await fetch(URL,
                {
                    method: 'GET',
                    headers: headers,
                }
            )
            const data = await response.json()
            return data;
        } catch (erro){
            return erro
        }
    })();

}

export const getPagamentos = (id, chave) => {
    return (async () => {
        try {
            const URL = baseUrlProcompetidor + "/getPedidos?id=" + id+"&chave="+chave;
            var headers = new Headers();
            headers.append("Content-Type", "application/json")
            const response = await fetch(URL,
                {
                    method: 'GET',
                    headers: headers,
                }
            )
            const data = await response.json()
            return data;
        } catch (err) {
            return err
        }
    })();

}

export const criaPagamento = (dados, chave) => {
    if (chave === 0) {
        return (async () => {
            try {
                const URL = baseUrl + `/v1/payments`;
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token)
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "post",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                console.log(data)
                return data;
            } catch {
                return 2
            }
        })();
    } else {
        return (async () => {
            try {
                const URL = baseUrl + `/v1/payments`;
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + tokenProcompetidor)
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "post",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                console.log(data)
                return data;
            } catch {
                return 2
            }
        })();
    }

}
export const atualizaPagamento = (id, dados, chave) => {
    if (chave === 0) {
        return (async () => {
            try {
                const URL = baseUrl + `/v1/payments/${id}`;
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token)
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "put",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                return data;
            } catch {
                return 2
            }
        })();
    } else {
        return (async () => {
            try {
                const URL = baseUrl + `/v1/payments/${id}`;
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + tokenProcompetidor)
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "put",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                return data;
            } catch {
                return 2
            }
        })();
    }

}

// Nova Versão

export const geraPreferenciaMP = async (dados, origemChavePgto) =>{
    
    // const URL = "http://127.0.0.1:5001/demo-teste/us-central1/app/mercadopago/gera-preferencia";
    const URL = "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/mercadopago/gera-preferencia";
    
    try {
        
        var headers = new Headers();
        headers.append("Content-Type", "application/json")
        const response = await fetch(URL,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    dados: dados,
                    origemChavePgto: origemChavePgto
                })
            }
        )

        if(!response?.api_response?.status === 201){
            return null;
        }

        const retorno = await response.json()
        const linkMercadoPago = retorno.init_point;
        // const linkMercadoPago = retorno.sandbox_init_point;
        // window.open(linkMercadoPago, "_blank");

        return linkMercadoPago;


    } catch (err) {
        console.log('Erro ao criar preferencia: ', err);
        return null;
    }
}

export const consultaPagamentosMP = async (external_reference, origemChavePgto) =>{

    // const URL = "http://127.0.0.1:5001/demo-teste/us-central1/app/mercadopago/consulta-pagamentos";

    const URL = "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/mercadopago/consulta-pagamentos";

   
    try {

        const response = await fetch(URL,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    origemChavePgto: origemChavePgto,
                    external_reference: external_reference
                }
            }
        )

        if(!response?.api_response?.status === 200){
            return null;
        }

        const retornoPagamentos = await response.json()
        console.log("retornoPagamentos: ", retornoPagamentos);

    } catch (err) {
        console.log('Erro ao buscar pagamentos: ', err);
        return null;
    }
}

export const atualizaPagamentoMP = async (external_reference, origemChavePgto) =>{
    
    // const URL = "http://127.0.0.1:5001/demo-teste/us-central1/app/mercadopago/atualiza-pagamento";
    const URL = "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/mercadopago/atualiza-pagamento";

    try {

        const response = await fetch(URL,
            {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    origemChavePgto: origemChavePgto,
                    external_reference: external_reference
                }
            }
        )

        const retorno = await response.json();

        console.log('Atualiza Pagamento: ', retorno);
        
        
        return retorno;

    } catch (err) {
        console.log('Erro ao buscar pagamentos: ', err);
        return null;
    }
}