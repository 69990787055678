import moment from "moment";
import firebase from 'firebase'
import Swal from "sweetalert2";


export const useUtilitarios = () => {


  const atualizarStatusFiliacao = async (idFederacao, usuarios) => {

    const firebaseCollectionsRefferences = {
      federacoes: firebase.firestore().collection('federacoes'),
      filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes')
    }
  

    const mensagemLoading = (msg) => {
      Swal.fire({
        title: 'Aguarde',
        html: msg,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        },
      })
    }

    mensagemLoading('carregando dados...')

    // Atualizar o status da filiação quando passado do vencimeto

    const aprovado = 2;
    const vencido = 4;
    const filiacaoPaga = '2'
    const anuidade = 2
    const aposUmAno = '1';
    const aoFimDoAno = '2';



    let federacao = {}

    const snapFederacao = await firebaseCollectionsRefferences.federacoes.doc(idFederacao).get()

    if (snapFederacao.exists) {
      federacao = snapFederacao.data()
    }

    if ([aposUmAno, aoFimDoAno].includes(federacao?.regrasFiliacao?.vencimentoAnuidade)) {

      let usuariosAlterados = 0

      for (let usuario of usuarios) {

        if (usuario?.filiacoes?.length > 0) {
          if (usuario?.filiacoes.includes(idFederacao)) {

            const snapFiliacao = await firebaseCollectionsRefferences.federacoes.doc(idFederacao).collection('filiacoes').where('idFiliado', '==', usuario.id).where('tipoUsuario', '==', usuario.tipoUsuario).get();

            if (!snapFiliacao.empty) {
              const filiacao = snapFiliacao.docs.map(d => d.data())[0];

              if (filiacao?.statusFiliacao === aprovado) {


                if (filiacao?.statusFiliacao === aprovado) {

                  if (filiacaoVencida(filiacao?.dtAprovado, federacao?.regrasFiliacao?.vencimentoAnuidade)) {

                    let dados = {
                      statusFiliacao: vencido,
                      statusImagemFoto: vencido,
                      statusImagemRg: vencido,
                      dtRgAprovado: null,
                      dtFotoAprovado: null
                    }

                    if (filiacao.opcaoEntregaSelecionado) {
                      dados = {
                        ...dados,
                        opcaoEntregaSelecionado: ''
                      }
                    }

                    if (filiacao.adquirirCarteirinhaFisica) {
                      dados = {
                        ...dados,
                        adquirirCarteirinhaFisica: ''
                      }
                    }

                    if (filiacao.enderecoEntregaCarteirinha) {
                      dados = {
                        ...dados,
                        enderecoEntregaCarteirinha: null
                      }
                    }

                    if (filiacao.dadosCarteirinha) {
                      dados = {
                        ...dados,
                        dadosCarteirinha: null
                      }
                    }

                    if (filiacao.adquirirCarteirinhaDigital) {
                      dados = {
                        ...dados,
                        adquirirCarteirinhaDigital: ''
                      }
                    }
                    if (filiacao.habilitarCarteirinhaDigital) {
                      dados = {
                        ...dados,
                        habilitarCarteirinhaDigital: ''
                      }
                    }
                    if (filiacao.dtAprovado) {
                      dados = {
                        ...dados,
                        dtAprovado: null
                      }
                    }

                    await firebaseCollectionsRefferences.filiacoes.doc(filiacao.id).update(dados)

                    usuariosAlterados++;

                  }
                }


              }

            }

          }
        }

      };

      // se houver altum usuário alterado, atualizar a página para que a informação seja atualizada.

      if (usuariosAlterados > 0) {
        window.location.reload()
      }

    }

    Swal.close()

  }

  const filiacaoVencida = (dtAprovado, tipoVencimento) => {

    const converterData = (data) => {
      return moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('YYYY-MM-DD HH:mm')
    }

    const aposUmAno = '1';
    const aoFimDoAno = '2';

    if (tipoVencimento == aposUmAno) {
      const diasPassados = moment().diff(moment(converterData(dtAprovado)), 'days')
      return (diasPassados > 365);
    }

    if (tipoVencimento == aoFimDoAno) {

      const _dtAprovado = converterData(dtAprovado);
      const anoFiliacao = new Date(_dtAprovado).getFullYear();
      const diasParaOFimDoAno = moment(`${anoFiliacao}-12-31 23:59`).diff(moment(), 'days')

      return (diasParaOFimDoAno < 0);
    }

    return false;

  }

  const excluirFiliacao = async (dadosExclusao) => {

    const idFederacao = dadosExclusao.idFederacao;
    const filiacao = dadosExclusao.filiacao;
    const usuarioFiliacao = dadosExclusao.usuarioFiliacao;
    const usuario = dadosExclusao.usuario
    const pagamentoPendente = dadosExclusao.pagamentoPendente;
    const pagamentosAprovados = dadosExclusao.pagamentosAprovados;

    const firebaseCollectionsRefferences = {
      federacoes: firebase.firestore().collection('federacoes'),
      filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
      usuarios: firebase.firestore().collection("usuarios")
      
    }
  


    try {
      // excluir Filiação
      // Remover a referencia da federação da collection usuarios

      await firebaseCollectionsRefferences.federacoes.doc(idFederacao).collection('filiacoes').doc(filiacao.id).delete();

      if (['competidor', 'responsavel', 'professor', 'familia'].includes(usuarioFiliacao.tipoUsuario)) {
        await firebaseCollectionsRefferences.usuarios.doc(usuarioFiliacao.id).update({
          filiacoes: usuarioFiliacao.filiacoes.filter(fdr => fdr !== idFederacao)
        })
      } else if (usuarioFiliacao.tipoUsuario === 'aluno') {
        firebaseCollectionsRefferences.usuarios.doc(usuario.id).collection('competidores').doc(usuarioFiliacao.id).update({
          filiacoes: usuarioFiliacao.filiacoes.filter(fdr => fdr !== idFederacao)
        })
      }

      // remover os arquivos anexados

      let caminho = `federacoes/${idFederacao}/filiacoes/${filiacao.id}`

      try {
        await firebase.storage().ref(`${caminho}/rg`).delete();
      } catch (error) {
        console.log('Erro ao excluir rg: ', error);
      }

      try {
        await firebase.storage().ref(`${caminho}/foto`).delete();

      } catch (error) {
        console.log('Erro ao excluir foto: ', error);
      }

      // if (pagamentosAprovados?.length > 0) {
      //   for (let pagamento of pagamentosAprovados) {

      //     try {
      //       await firebase.storage().ref(`${caminho}/pagamentos/${pagamento.id}`).delete();

      //     } catch (error) {
      //       console.log('Erro ao excluir pagamento: ', error.message);

      //     }

      //   }
      // }

      // if (pagamentoPendente?.id) {

      //   try {
      //     await firebase.storage().ref(`${caminho}/pagamentos/${pagamentoPendente.id}`).delete();

      //   } catch (error) {
      //     console.log('Erro ao excluir pagamento: ', error.message);

      //   }

      // }


    } catch (error) {
      console.log('erro: ', error.message);
      throw new Error('Não foi possível concluír a exclusão. Entre em contato com o suporte.')
    }



  }

  const verificaCampoDisponivel = async (item, comparacao, collectionRefference) => {

    // validar se um dado campo (ex: CPF ou Email) está disponível ou já existe cadastrado.

    try {
      const snap = await collectionRefference.where(item, '==', comparacao).get();
      if (snap.empty) {
        return true
      } else if (!snap.empty) {
        return false
      }
    } catch (error) {
      console.log(error);
    }
  }

      async function uploadArquivo(caminho, arquivo) {
  
          return new Promise((resolve, reject) => {
  
              var uploadTask = firebase.storage().ref().child(caminho).put(arquivo, { contentType: arquivo.type });
  
              uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                  var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  switch (snapshot.state) {
                      case firebase.storage.TaskState.PAUSED: // or 'paused'
                          console.log('uploadImagem is paused');
                          break;
                      case firebase.storage.TaskState.RUNNING: // or 'running'
                          console.log(`Salvando ${arquivo.name}`);
                          break;
                      default:
                          console.log('Passou')
                  }
              }, (error) => {
                  console.error(`Error during upload: ${error.message}`);
                  reject(error); // Ensure promise is rejected on error
              }, async () => {
  
  
                  try {
  
                      const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                      resolve(downloadURL);

                  } catch (error) {
  
                      console.error(`Unexpected error in uploadArquivo: ${error.message}`);
                      reject(error);
                  }
  
              });
  
          });
  
      }

  return { atualizarStatusFiliacao, verificaCampoDisponivel, excluirFiliacao, uploadArquivo }
}